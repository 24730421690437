import SimpleAlert from '@/components/dialog/simpleAlert/SimpleAlert.vue'

export default {
  //props格式:
  //  list -- 研究清單(Array)，清單內容如下(JSON物件格式，有數值的為範例):
  //    {
  //      id: '', //研究案ID(v-for組合key用)
  //      name: '', //研究名稱
  //      link: '', //研究連結
  //      host: '', //主持人
  //      location: '', //地點
  //      duration: '', //起訖時間
  //      attribute: '', //研究變項
  //      coverage: '', //覆蓋率
  //      rating: 2, //信用評價(數值)
  //      status: [], //狀態(文字陣列)
  //    }
  components: { SimpleAlert },
  props: [
    'dataList',
    'pagination',
    'backendPagination',
    'totalCount',
    'queryMethod',
    'initPage',
    'loading',
  ],
  data() {
    return {
      page: 1,
      itemsPerPage: 5,
      showAuditComment: false,
      commentTitle: this.$t('reply'),
      commentContent: '',
    }
  },
  computed: {
    studyList() {
      if (this.backendPagination) {
        return this.dataList
      }
      if (this.pagination && this.itemsPerPage !== -1) {
        return this.dataList.slice(
          (this.page - 1) * this.itemsPerPage,
          (this.page - 1) * this.itemsPerPage + this.itemsPerPage,
        )
      } else {
        return this.dataList
      }
    },
    itemsLength() {
      if (this.backendPagination) {
        return this.totalCount
      }
      return this.dataList.length
    },
    pageStart() {
      return (this.page - 1) * this.itemsPerPage
    },
    pageStop() {
      return this.page * this.itemsPerPage
    },
  },
  watch: {
    initPage: {
      handler: function () {
        //如果為新的查詢(比方以關鍵字查詢)重置頁碼
        if (this.initPage) {
          this.page = 1
        }
      },
    },
  },
  methods: {
    updatePage(options) {
      this.page = options.page
      this.itemsPerPage = options.itemsPerPage
      this.$emit('pageChange', options.page, options.itemsPerPage)
    },
    showComment(study) {
      this.showAuditComment = true
      this.commentContent = study.auditComment
    },
  },
}
